<template>
  <section class="story-campaign-group">
    <h2 class="story-campaign-group__title" data-aos="fade-up">{{ group.title }}</h2>
    <div
      v-if="group.content"
      v-html="group.content"
      class="story-content-part--default story-campaign-group__content container"
      data-aos="fade-up"
    />
    <div v-else class="story-campaign-group__items">
      <ul class="story-campaign-group-items row">
        <li
          v-for="item in group.items.data"
          :key="item.id"
          class="story-campaign-group-items__item col-sm-12 col-md-6 col-lg-4"
          data-aos="fade-up"
        >
          <StoryCampaignGroupItem :item="item" />
        </li>
      </ul>

      <button
        v-if="group.items.hasMorePages"
        class="story-campaign-group__btn-load-more"
        data-aos="fade-up"
        type="button"
        @click="loadMore"
      >
        Load more
      </button>
    </div>
  </section>
</template>

<script>
import StoryCampaignGroupItem from '@/components/partials/StoryCampaignGroupItem';

export default {
  name: 'StoryCampaignGroup',
  components: { StoryCampaignGroupItem },
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  methods: {
    loadMore() {
      this.$emit('load-more', this.group.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.story-campaign-group {
  margin-top: 80px;

  @media (min-width: 768px) {
    margin-top: 105px;
  }

  &__title {
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 3px;
    line-height: 38px;
    text-transform: uppercase;
    color: #363636;

    @media (min-width: 768px) {
      margin-bottom: 10px;
      font-size: 30px;
      letter-spacing: 8.33px;
    }
  }

  &__content {
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 768px) {
      margin-top: 105px;
    }

    &.container {
      @media (min-width: 992px) {
        max-width: 950px;
      }
      @media (min-width: 1200px) {
        max-width: 950px;
      }
    }

    ::v-deep * {
      font-size: 16px !important;

      @media (min-width: 768px) {
        font-size: 20px !important;
      }
    }
    ::v-deep p,
    ::v-deep div {
      margin-top: 35px;
      line-height: 34px;
    }
  }

  &-items {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    list-style-type: none;

    &__item {
      margin-top: 40px;
    }
  }

  &__btn-load-more {
    margin-top: 40px;
    padding: 0;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 1.11px;
    line-height: 26px;
    text-transform: uppercase;
    color: #363636;
    border-bottom: 1px solid #363636;

    &:hover {
      border-bottom-color: transparent;
    }
  }
}
</style>
