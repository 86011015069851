<template>
  <div class="story-content">
    <StoryDescriptionTextPart
      v-for="(part, idx) in story.descriptionParts"
      :key="`${story.id}_${part.__typename}_${idx}`"
      :text="part.text"
    />

    <StoryCampaignActions
      class="container"
      :show-art-fair-attend-btn="showArtFairAttendBtn"
      :story="story"
      @request-art-fair="showArtFairAttendModal = true"
    />

    <div class="story-campaign-groups container">
      <StoryCampaignGroup
        v-for="group in campaignGroups"
        :key="group.id"
        class="story-campaign-groups__group"
        :group="group"
        @load-more="loadMore"
      />
    </div>

    <Transition name="slide">
      <LikeToAttend
        v-if="showArtFairAttendModal"
        :event="story.campaignArtFair"
        @close="showArtFairAttendModal = false"
        @requested="handleRequested"
      />
    </Transition>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import storyCampaignGroupsQuery from '@/graphql/story/StoryCampaignGroups.query.gql';

import StoryCampaignActions from '@/components/partials/StoryCampaignActions';
import StoryCampaignGroup from '@/components/partials/StoryCampaignGroup';
import StoryDescriptionTextPart from '@/components/partials/StoryDescriptionTextPart';

const GROUPS_PAGINATION_LIMIT = 3;

export default {
  name: 'StoryCampaignGroupsTemplate',
  components: {
    StoryDescriptionTextPart,
    StoryCampaignGroup,
    StoryCampaignActions,
    LikeToAttend: () => import('@/components/partials/LikeToAttend.vue'),
  },
  props: {
    story: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      campaignGroups: [],
      loadGroupsIds: [],
      loadMoreMode: false,
      loadPage: 1,
      pages: {},
      requestedArtFairAttend: false,
      showArtFairAttendModal: false,
    };
  },
  computed: {
    ...mapState(['museumRole']),
    showArtFairAttendBtn() {
      return !this.museumRole && this.story.campaignArtFair && !this.requestedArtFairAttend;
    },
  },
  watch: {
    'story.campaignArtFair': {
      immediate: true,
      handler(campaignArtFair) {
        this.requestedArtFairAttend = !!campaignArtFair?.activeBooking;
      },
    },
    'story.campaignGroups': {
      immediate: true,
      handler(campaignGroups) {
        this.loadGroupsIds = campaignGroups.map((gr) => gr.id);
        this.loadGroupsIds.forEach((id) => {
          this.pages[id] = 1;
        });
      },
    },
  },
  apollo: {
    storyCampaignGroups: {
      query: storyCampaignGroupsQuery,
      variables() {
        return {
          ids: this.loadGroupsIds,
          limit: GROUPS_PAGINATION_LIMIT,
          page: this.loadPage,
        };
      },
      skip() {
        return !this.loadGroupsIds.length;
      },
      result(result) {
        if (this.loadMoreMode) {
          const group = result.data.storyCampaignGroups[0];
          const index = this.campaignGroups.findIndex((gr) => gr.id === group.id);

          this.campaignGroups[index].items.hasMorePages = group.items.hasMorePages;
          this.campaignGroups[index].items.data.push(...group.items.data);
          return;
        }

        this.campaignGroups = result.data.storyCampaignGroups.filter((gr) => {
          return gr.content || (gr.items && gr.items.data.length);
        });
      },
    },
  },
  methods: {
    handleRequested() {
      this.requestedArtFairAttend = true;
      this.showArtFairAttendModal = false;
    },
    loadMore(groupId) {
      this.loadMoreMode = true;
      this.loadGroupsIds = [groupId];
      this.loadPage = ++this.pages[groupId];
    },
  },
};
</script>

<style lang="scss" scoped>
.story-campaign-groups {
  margin-top: 80px;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    margin-top: 110px;
    margin-bottom: 50px;
  }
}
</style>
