<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="20"
    height="18"
    viewBox="0 0 20 18"
  >
    <defs>
      <path
        id="8a2ba"
        d="M404.69 551a.82.82 0 0 1-.47-.14l-5.22-3.6-5.22 3.6c-.28.19-.66.19-.93 0a.73.73 0 0 1-.3-.84l2-5.8-5.22-3.6a.73.73 0 0 1-.3-.83.8.8 0 0 1 .76-.52h6.46l2-5.8c.2-.62 1.3-.62 1.5 0l2 5.8h6.46a.8.8 0 0 1 .75.52c.1.3 0 .64-.29.84l-5.22 3.58 2 5.8c.1.32-.01.66-.3.85-.13.1-.3.14-.46.14z"
      ></path>
    </defs>
    <g>
      <g transform="translate(-389 -533)">
        <use xlink:href="#8a2ba"></use>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TheFavoritesIcon',
};
</script>
